<template>
    <div class="home-container recommendation-template">
        <div>
            <!-- If community recommended -->
            <div v-if="this.currentRecommendation.community_name">
                <CommunityRecommendation :recommendationData="recommendationData" />
            </div>
            <!-- If user recommended -->
            <div
                v-else-if="currentRecommendation.email && currentRecommendation.first_name && currentRecommendation.last_name">
                <UserRecommendation :recommendationData="recommendationData" />
            </div>
            <!-- If no more users or communitFies to recommend -->
            <div v-else>
                <FinishedRecommendation :hasCommunities="hasCommunities" />
            </div>
        </div>

        <!-- If there was an issue storing the interaction -->
        <div v-if="errorMessage" class="error-banner" aria-live="assertive">
            <i class="bi bi-exclamation-circle-fill" aria-hidden="true"></i>
            {{ errorMessage }}
        </div>

        <!-- Buttons at the bottom of the container -->
        <div class="recommendation-buttons">
            <!-- Edit Search button -->
            <button :class="['secondary-button', { 'full-width': recommendationFinished }]" @click="backToSearch"
                aria-label="Back to Search Button">
                <div class="button-content">
                    <div class="button-col">
                        <i class="bi bi-arrow-left"></i>
                    </div>
                    <div class="button-col" v-if="!this.recommendationFinished">
                        Edit<br>Search
                    </div>
                    <div class="button-col" v-if="this.recommendationFinished">
                        Back To<br>Search!
                    </div>
                </div>
            </button>
            <!-- Connect button -->
            <button class="primary-button" :class="{ connected: connected }" :disabled="connected" @click="connect"
                v-if="!this.recommendationFinished" aria-label="Connect Button">
                {{ connected ? 'Connected' : 'Connect' }}
            </button>
            <!-- Search again button -->
            <button class="quartenary-button" @click="searchAgain" v-if="!this.recommendationFinished"
                aria-label="Search Again Button">
                Search<br>Again
            </button>
        </div>
    </div>
</template>

<script>
import CommunityRecommendation from './CommunityRecommendation.vue';
import FinishedRecommendation from './FinishedRecommendation.vue';
import UserRecommendation from './UserRecommendation.vue';
import axiosClient from '../../../axiosClient';
import { EventBus } from '@/EventBus';

export default {
    name: "RecommendationContainer",
    props: {
        recommendationList: {
            type: Array,
            required: true,
        },
        connectionPurpose: {
            type: String,
            required: true,
        },
        searchDescription: {
            type: String,
            required: true,
        },
        inPerson: {
            type: Boolean,
            required: true,
        },
        virtual: {
            type: Boolean,
            required: true,
        },
        out_of_work: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            currentRecommendation: this.recommendationList[0],  // Take first user in list
            recommendationData: {},  // Store detailed data
            localRecommendationList: [...this.recommendationList.slice(1)], 
            recommendationFinished: false,  // When no more data to show
            errorMessage: "",
            connected: false,
            hasCommunities: this.recommendationList.some(rec => rec.community_name !== undefined),
        }
    },
    components: {
        UserRecommendation,
        CommunityRecommendation,
        FinishedRecommendation
    },
    async mounted() { // Load first user
        if (this.currentRecommendation.email) {  // If user
            try {
                // Search for detailed user profile
                const requestData = {
                    'user_id': this.currentRecommendation.id,
                    'description': this.searchDescription
                }
                const response = await axiosClient.post("/search/user", requestData)

                // To be passed as parameter to UserRecommendation
                this.recommendationData = response.data.user

            } catch (error) {
                this.errorMessage = "Error fetching user data. Please refresh or try again"
            }

        }
    },
    methods: {

        async backToSearch() {

            this.errorMessage = "";

            // If it is a user and they are not connected
            if (this.currentRecommendation.email && !this.connected) {

                try {
                    // Record a negative (-1) interaction as the users did not connect
                    const requestData = {
                        user2_id: this.currentRecommendation.id,
                        strength: -1,  // Negative interaction
                        connected: false,
                        connection_purpose: this.connectionPurpose,   // Will be ignored in BE if connected=false
                        in_person: this.inPerson,   // Handled these in BE to set a connection location
                        virtual: this.virtual,
                        out_of_work: this.out_of_work,
                    }
                    // Create interaction
                    await axiosClient.post("/connect/user", requestData);

                } catch (error) {
                    this.errorMessage = "Error navigating to the search page. Please refresh or try again";
                }
            }

            // If there is an error message then stop and do not return to search
            if (this.errorMessage) {
                return
            } else {
                // Switch to search component if no errors
                this.$emit("back-to-search");
            }

        },

        async searchAgain() {
            this.errorMessage = "";
            // If it is a user and they are not connected
            if (this.currentRecommendation.email && !this.connected) {

                try {
                    // Record a negative (-1) interaction as the users did not connect
                    const requestData = {
                        user2_id: this.currentRecommendation.id,
                        strength: -1,
                        connected: false,
                        connection_purpose: this.connectionPurpose,   // will be ignored in BE if connected=false
                        in_person: this.inPerson,   // handle these in BE
                        virtual: this.virtual,
                        out_of_work: this.out_of_work,
                    }

                    // Create interaction
                    await axiosClient.post("/connect/user", requestData);

                } catch (error) {
                    this.errorMessage = "Error fetching next recommendation. Please refresh or try again";
                }
            }

            // Load the next user, reset the variables
            this.errorMessage = "";
            this.connected = false;

            // If there are remaining recommendations
            if (this.localRecommendationList.length > 0) {

                // Pop the top recommendation to be now the current one
                this.currentRecommendation = this.localRecommendationList.shift();

                // If it is a user
                if (this.currentRecommendation.email) {

                    try {
                        // Fetch the detailed user profile
                        const requestData = {
                            'user_id': this.currentRecommendation.id,
                            'description': this.searchDescription
                        }
                        const response = await axiosClient.post("/search/user", requestData)

                        // To be passed as parameter to UserRecommendation    
                        this.recommendationData = response.data.user

                    } catch (error) {
                        this.errorMessage = "Error fetching user data. Please refresh or try again"
                    }


                } else if (this.currentRecommendation.community_name) {
                    // Else if it is a community
                    try {
                        // Fetch the detailed community profile
                        const requestData = {
                            'community_id': this.currentRecommendation.id,
                            'description': this.searchDescription
                        }
                        const response = await axiosClient.post("/search/community", requestData)

                        // To be passed as parameter to CommunityRecommendation   
                        this.recommendationData = response.data.community

                    } catch (error) {
                        this.errorMessage = "Error fetching community data. Please refresh or try again"
                    }
                }

                // End of recommendation list
            } else {
                this.currentRecommendation = {};
                this.recommendationFinished = true;
            }
        },

        async connect() {
            // Connected to the user
            this.connected = true

            // If it is a user
            if (this.currentRecommendation.email) {

                try {
                    // Record positive interaction
                    const requestData = {
                        user2_id: this.currentRecommendation.id,
                        strength: 2, // Positive interaction
                        connected: true, // Connected 
                        connection_purpose: this.connectionPurpose,   // Will be ignored in BE if connected=false
                        in_person: this.inPerson,   // Handled connection location in BE
                        virtual: this.virtual,
                        out_of_work: this.out_of_work,
                    }

                    // Create interaction
                    await axiosClient.post("/connect/user", requestData);

                    // Trigger refresh in recent connections
                    this.$emit("new-connection")

                } catch (error) {
                    this.errorMessage = "Error connecting. Please refresh or try again";
                }
            } else {  // If it is a community

                try {

                    // Create a community connection
                    const requestData = {
                        community_id: this.currentRecommendation.id
                    }

                    // Create connection
                    await axiosClient.post("/connect/community", requestData);

                    // Trigger refresh in recent connections
                    this.$emit("new-connection")

                } catch (error) {
                    this.errorMessage = "Error connecting. Please refresh or try again";
                }
            }

            EventBus.emit("award-kudos", 50)

        }
    },
};
</script>
