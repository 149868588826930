<template>
    <div class="main-page-layout">

        <!-- Kudos awarded message -->
        <div class="profile-banner thanks" v-if="thanksBanner">

            <div class="profile-banner-content thanks" aria-live="polite">
                <!-- Text containing information message -->
                <p class="profile-banner-text"><i class="bi bi-x-circle attributes-remove" @click="closeThanksBanner"
                        role="button" aria-label="Button to close thanks banner"></i> You have been awarded {{
                            awardedKudos }} kudos points!
                </p>

            </div>

        </div>

        <div class="main-row">

            <!-- User Connections on left side of scren / top -->
            <div class="main-column">
                <AllUserConnectionsContainer />
            </div>

            <!-- Manage Communities listed on right side of screen / below -->
            <div class="main-column">
                <AllCommunityConnectionsContainer />
            </div>

        </div>

    </div>
</template>

<script>
import AllCommunityConnectionsContainer from '@/components/common/AllCommunityConnectionsContainer.vue';
import AllUserConnectionsContainer from '@/components/common/AllUserConnectionsContainer.vue';
import { EventBus } from '@/EventBus';


export default {
    name: "all-connections-page",
    components: {
        AllUserConnectionsContainer,
        AllCommunityConnectionsContainer
    },
    mounted() {
        EventBus.on("award-kudos", (kudos) => this.showThanksBanner(kudos))
    },
    unmounted() {
        EventBus.off("award-kudos", (kudos) => this.showThanksBanner(kudos))
    },
    data() {
        return {
            // Thanks Banner
            thanksBanner: false,
            awardedKudos: 0,
        }
    },
    methods: {
        showThanksBanner(kudos) {
            // Show thanks banner
            this.thanksBanner = true;
            this.awardedKudos = kudos;

            // Close banner after 5 seconds
            setTimeout(() => {
                this.closeThanksBanner();
            }, 5000);
        },
        closeThanksBanner() {
            // Close thanks banner
            this.thanksBanner = false;
        }
    }
}
</script>
