<template>
    <!-- Joined Community Connection Card -->
    <div class="connection-container manage">

        <!-- Leave Community -->
        <div class="connection-top leave">
            <LeaveCommunityButton :communityName="joinedCommunity.community_name" @leave-community="handleLeave" />
        </div>

        <!-- Icons at the top of the card -->
        <div class="manage-connections-icons joined">

            <!-- Community members icon-->
            <div class="manage-community-member-icon">
                <i class="bi bi-person" v-if="!membersToggle" @click="toggleMembers" tabindex="0" @keydown="handleMembersKeyDown" role="button" aria-label="View Community Members"></i>

                <!-- Colour in icon when toggled -->
                <i class="bi bi-person-fill" v-else-if="membersToggle" @click="toggleMembers" tabindex="0" @keydown="handleMembersKeyDown" role="button" aria-label="Hide Community Memebrs"></i>
            </div>

        </div>

        <!-- Community Name -->
        <div class="connection-name">
            <p>{{ joinedCommunity.community_name }}</p>
        </div>

        <!-- When toggle not pressed, display community info -->
        <div v-if="!membersToggle" class="connection-all-info">

            <!-- Community Description -->
            <div class="manage-community-description">
                <p><span class="manage-community-field">About: </span>{{ joinedCommunity.description }}</p>
            </div>

            <!-- Community Purpose -->
            <div class="manage-community-purpose">
                <p><span class="manage-community-field">Purpose: </span>{{ formattedCommunityPurpose }}</p>
            </div>

            <!-- Community Location -->
            <div class="manage-community-location">
                <p><span class="manage-community-field">Where: </span>{{ formattedCommunityLocation }}</p>
            </div>

        </div>

        <!-- Community Meet Schedule -->
        <div v-if="!membersToggle" class="connection-bottom">
            <p><span class="manage-community-field">Schedule: </span>{{ joinedCommunity.meet_schedule }}</p>
        </div>

        <!-- When members toggle pressed, display members -->
        <div v-else-if="membersToggle" class="connection-members" tabindex="0">

            <!-- Common office days-->
            <div class="office-section">
                <span class="bold-text">Common Office Days:</span>
                <div class="office-days" v-if="communityMembers.office">
                    <span :class="{ 'active-day': communityMembers.office.mon_office }" aria-label="Monday">M</span>
                    <span :class="{ 'active-day': communityMembers.office.tues_office }" aria-label="Tuesday">T</span>
                    <span :class="{ 'active-day': communityMembers.office.weds_office }" aria-label="Wednesday">W</span>
                    <span :class="{ 'active-day': communityMembers.office.thurs_office }" aria-label="Thursday">T</span>
                    <span :class="{ 'active-day': communityMembers.office.fri_office }" aria-label="Friday">F</span>
                </div>

            </div>

            <ul>
                <!-- Display owner first and highlight -->
                <li class="connection-owner" aria-label="Community Owner"> {{ communityMembers.owner }}</li>

                <!-- Iterate through members -->
                <li v-for="(member, index) in communityMembers.members" :key="index">
                    {{ member }}
                </li>
            </ul>
        </div>

        <!-- Say Thanks / Schedule Meet buttons -->
        <ConnectionButtons :connection="joinedCommunity" />
    </div>

</template>

<script>
import axiosClient from '../../../axiosClient';
import ConnectionButtons from './ConnectionButtons.vue';
import LeaveCommunityButton from './LeaveCommunityButton.vue';

export default {
    name: "joinedCommunityConnection",
    data() {
        return {
            membersToggle: false,
            communityMembers: [],
        }
    },
    props: {
        joinedCommunity: {
            type: Object,
            required: true,
        },
    },
    components: {
        LeaveCommunityButton,
        ConnectionButtons
    },
    computed: {
        formattedCommunityPurpose() {
            // Removes underscore from joinedCommunity.purpose, returns "Not Found" if not available
            return this.joinedCommunity?.purpose
                ? this.joinedCommunity.purpose.replace(/_/g, ' ')
                : "Not Found";
        },
        formattedCommunityLocation() {
            // Removes underscore from joinedCommunity.meet_location, returns "Not Found" if not available
            return this.joinedCommunity?.meet_location
                ? this.joinedCommunity.meet_location.replace(/_/g, ' ')
                : "Not Found";
        }

    },
    methods: {
        async toggleMembers() {
            // Toggle community members
            this.membersToggle = !this.membersToggle;

            // When toggled to true
            if (this.membersToggle) {
                try {

                    // Fetch community members
                    const response = await axiosClient.get(`/connect/community_members/${this.joinedCommunity.community_name}`,);
                    this.communityMembers = response.data;

                } catch (error) {

                    console.error("Failed to fetch community members", error);
                    // Reset members on error
                    this.communityMembers = [];

                }
            }
        },
        handleLeave() {
            // Request a refresh to the community list if leave is triggered
            this.$emit('leave-joined-community')
        },
        handleMembersKeyDown(event) {
            // Handle pressing enter on the members icon
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleMembers();
                event.preventDefault();
            }
        },
    }
}
</script>