<template>
    <div class="footer">
        <!-- System Description -->
        <div class="system-description">
            <h4>PwC People Link</h4>
            <h5>
                PwC People Link is an application to allow employees
                to easily network with others both cross office and x-los.
                Use the search panel to search for a new networking opportunity,
                or create a community to get started. To improve your experience,
                set your skills, interests and preferences on your profile.
            </h5>
        </div>
        <br>
        <!-- GDPR Link -->
        <div class="GDPR-link" @click="toggleGDPR" tabindex="0" @keydown="handleGDPRKeyDown" role="button" aria-label="Show GDPR Statement" title="Show GDPR statement">
            <h4>GDPR Statement</h4>
            <!-- Toggle visibility of full statement -->
            <h5 v-if="showGDPR">
                <br>
                <b>Collecting and Using Your Personal Data</b>
                <br><br>
                To use PwC People Link, we ask for a minimum of your email address and a password. Additional
                information can also be optionally shared and will be displayed to other users in the event of your
                account being recommended to or searched for by another user. The entered information may be modified at
                any point in time. Personally identifiable information that may be displayed to other users includes:
                first name and last name, base office, grade, line of service, business area, current project, duration
                left on the project, skills, certifications, interests and preferred office days.
                <br><br>
                Your data will be used to recommend connections to you, as well as to recommend your profile to other
                users when they are searching for a connection. The data entered on your account, as well as your
                connection history will be used for this process to lead to strong recommendations. The do not disturb
                checkbox in the preferences page can be used to stop your account from being considered for
                recommendations to other users.
                <br><br>
                <b>Retention of Your Personal Data</b>
                <br><br>
                The system will retain your Personal Data for as long as necessary. Data about your connections will be
                held for a maximum of one year, after which the data will be deleted to avoid redundancy.
                <br><br>
                <b>Modifying Your Personal Data</b>
                <br><br>
                You have the right to delete or request that we assist in deleting the Personal Data that we have
                collected about you. You may update, amend, or delete your account information at any time by signing
                into your account and visiting the account settings. You may also contact us to request access to,
                correct, or delete any personal information that you have provided to us.
                <br><br>
                <b>Disclosure of Your Personal Data</b>
                <br><br>
                Under certain circumstances, we may be required to disclose your Personal Data if required to do so by
                law or in response to valid requests by public authorities (e.g. a court or a government agency).
                <br><br>
                <b>Security of Your Personal Data</b>
                <br><br>
                We will ensure that all personal passwords are stored securely and all transmissions are transferred
                securely using HTTPS. Although this will aid security, all transmissions via the internet are at risk of
                security threats so we cannot guarantee that this information will definitely be secure during
                transmission.
                <br><br>
                <b>Essential Cookies</b>
                <br><br>
                We use Cookies to ensure authorisation and authentication for a user’s activities. If you do not accept
                Cookies, you will not be able to use the system as these cookies are essential for a valid user session.
                <br><br>
                <b>Optional Cookies</b>
                <br><br>
                Cookies will also be used to remember choices you make when you use the website, such as remembering
                your login details. The purpose of these cookies is to make the website easier to use, removing the need
                to repeatedly enter the same information. These cookies will be able to be disabled and the system will
                still be usable.
                <br><br>
                <b>Third Party Services</b>
                <br><br>
                The system will generate deep links which can be used to easily set up meetings with your connections.
                To use this functionality, please ensure that your Microsoft Teams account is active for your People
                Link account’s email.
                <br><br>
                <b>Changes to this Privacy Policy</b>
                <br><br>
                We may occasionally update our Privacy Policy. We will notify you of any changes by posting the new
                Privacy Policy on this page. You are advised to review this Privacy Policy regularly for any changes.
                Upon posting, they will become effective immediately.
                <br><br>
                <b>Contact Us</b>
                <br><br>
                If you have any questions about this Privacy Policy, please contact mfpeoplelink@gmail.com

            </h5>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageFooter",
    data() {
        return {
            showGDPR: false, // Default don't show it
        };
    },
    methods: {
        toggleGDPR() {    // Show GDPR
            this.showGDPR = !this.showGDPR;
        },
        handleGDPRKeyDown(event) {
            // Handle pressing enter on the GDPR Link
            if (event.key === 'Enter' || event.key === ' ') {
                this.toggleGDPR();
                event.preventDefault();
            }
        },
    },
}
</script>

<style>
@import '../../styles/main.scss';
</style>