<template>
	<div class="main-page-layout">

		<!-- Populate profile prompt -->
		<div class="profile-banner" v-if="profileBanner">

			<div class="profile-banner-content" aria-live="polite">
				<!-- Text containing route link and dynamic values of profile sections-->
				<p class="profile-banner-text"><i class="bi bi-x-circle attributes-remove" @click="closeBanner"
						role="button" aria-label="Button to close prompt banner"></i> To improve your experience, please
					update the following sections of
					<router-link to="/profile" class="all-connections-link" aria-label="Link to Profile Page">your
						profile:</router-link>
					{{ profileBannerText }}
				</p>

			</div>

		</div>

		<!-- Kudos awarded message -->
		<div class="profile-banner thanks" v-if="thanksBanner">

			<div class="profile-banner-content thanks" aria-live="polite">
				<!-- Text containing information message -->
				<p class="profile-banner-text"><i class="bi bi-x-circle attributes-remove" @click="closeThanksBanner"
						role="button" aria-label="Button to close thanks banner"></i> You have been awarded {{
							awardedKudos }} kudos points!
				</p>

			</div>

		</div>

		<div class="main-row">
			<div class="main-column">

				<!-- Hide search container when search pressed and display recommendations -->
				<SearchContainer v-if="showSearchContainer" @search-request-done="handleSearchDone" />

				<!-- Recommendation Controller - Params: Search Result and Search Params from SearchContainer -->
				<RecommendationContainer v-else :recommendationList="recommendationList"
					:connectionPurpose="connectionPurpose" :searchDescription="searchDescription" :inPerson="inPerson"
					:virtual="virtual" :out_of_work="out_of_work" @back-to-search="handleBackToSearch"
					@new-connection="handleNewConnection" />

			</div>

			<!-- Recent connections on right side of screen / below -->
			<div class="main-column">
				<RecentConnectionsContainer ref="recentConnections" />
			</div>

		</div>

	</div>
</template>

<script>
import RecentConnectionsContainer from "@/components/common/RecentConnectionsContainer.vue";
import SearchContainer from "@/components/common/SearchContainer.vue";
import RecommendationContainer from "@/components/common/RecommendationContainer.vue";
import axiosClient from "../../axiosClient";
import { EventBus } from "@/EventBus";

export default {
	name: "home-page",
	components: {
		SearchContainer,
		RecentConnectionsContainer,
		RecommendationContainer,
	},
	data() {
		return {
			showSearchContainer: true, // Flag to toggle between SearchContainer and RecommendationContainer
			recommendationList: [], // Holds the search results
			connectionPurpose: "",
			searchDescription: "",
			inPerson: true,
			virtual: true,
			out_of_work: true,

			// Populate profile banner
			profileBanner: false,
			profileBannerText: "",

			// Thanks Banner
			thanksBanner: false,
			awardedKudos: 0,
		};
	},
	mounted() {
		// Display prompt banner
		this.showBanner();

		// Award kudos banner listener
		EventBus.on("award-kudos", (kudos) => this.showThanksBanner(kudos))
	},
	unmounted() {
		EventBus.off("award-kudos", (kudos) => this.showThanksBanner(kudos))
	},
	methods: {
		handleSearchDone(rec, purpose, description, inPerson, virtual, out_of_work) {
			// Save results of search
			this.recommendationList = rec;
			this.connectionPurpose = purpose;
			this.searchDescription = description;
			this.inPerson = inPerson;
			this.virtual = virtual;
			this.out_of_work = out_of_work;

			// Disable SearchContainer and show recommendation container
			this.showSearchContainer = false;
		},
		handleBackToSearch() {
			// Show SearchContainer when "Edit Search" is clicked
			this.showSearchContainer = true;
			this.recommendationList = []; // Clear previous search results
		},
		handleNewConnection() {
			// Call refresh recent connections 
			this.$refs.recentConnections.refreshConnections();
		},
		async showBanner() {
			try {
				// Fetch empty fields
				const res = await axiosClient.get('/users/profile/prompt');
				const emptySections = res.data;

				// Fetch bool values from response
				const accountEmpty = emptySections.account;
				const skillsEmpty = emptySections.skills_and_specialisms;
				const certsEmpty = emptySections.certifications;
				const workInterestsEmpty = emptySections.work_interests;
				const personalInterestsEmpty = emptySections.personal_interests;

				// Set the text to the expected text if the flag is true
				const accountText = accountEmpty ? "Account" : ""
				const skillsText = skillsEmpty ? "Skills and Specialisms" : ""
				const certsText = certsEmpty ? "Certifications" : ""
				const workInterestsText = workInterestsEmpty ? "Work Interests" : ""
				const personalInterestsText = personalInterestsEmpty ? "Personal Interests" : ""

				// Combine empty field's names into a string
				const attributeNames = [accountText, skillsText, certsText, workInterestsText, personalInterestsText];
				const notNull = attributeNames.filter(attributeNames => attributeNames !== "");
				this.profileBannerText = notNull.join(", ");

				// If there's any empty fields, show the banner
				if (this.profileBannerText) {
					this.profileBanner = true;
				} else {
					this.profileBanner = false;
				}


			} catch (error) {
				console.error(error)
			}
		},
		closeBanner() {
			// Hide banner
			this.profileBanner = false;
		},
		showThanksBanner(kudos) {
			// Show thanks banner
			this.thanksBanner = true;
			this.awardedKudos = kudos;

			// Close banner after 5 seconds
			setTimeout(() => {
				this.closeThanksBanner();
			}, 5000);
		},
		closeThanksBanner() {
			// Close thanks banner
			this.thanksBanner = false;
		}
	},
};
</script>
