<template>
    <div class="main-page-layout">

        <!-- Kudos awarded message -->
        <div class="profile-banner thanks" v-if="thanksBanner">

            <div class="profile-banner-content thanks" aria-live="polite">
                <!-- Text containing information message -->
                <p class="profile-banner-text"><i class="bi bi-x-circle attributes-remove" @click="closeThanksBanner"
                        role="button" aria-label="Button to close thanks banner"></i> You have been awarded {{
                            awardedKudos }} kudos points!
                </p>

            </div>

        </div>

        <div class="main-row">

            <!-- Create Community on left side of scren / top -->
            <div class="main-column">
                <CreateCommunityContainer @community-created="handleCreate" @new-connection="handleConnection" />
            </div>

            <!-- Manage Communities listed on right side of screen / below -->
            <div class="main-column">
                <ManageCommunitiesContainer ref="refreshCommunities" />
            </div>

        </div>

    </div>
</template>

<script>
import CreateCommunityContainer from '@/components/common/CreateCommunityContainer.vue';
import ManageCommunitiesContainer from '@/components/common/ManageCommunitiesContainer.vue';
import { EventBus } from '@/EventBus';

export default {
    name: "manage-communities-page",
    components: {
        ManageCommunitiesContainer,
        CreateCommunityContainer
    },
    data() {
        return {
            // Thanks Banner
			thanksBanner: false,
			awardedKudos: 0,
        }
    },
    mounted() {
        // Award kudos banner listener
		EventBus.on("award-kudos", (kudos) => this.showThanksBanner(kudos))
	},
	unmounted() {
		EventBus.off("award-kudos", (kudos) => this.showThanksBanner(kudos))
	},
    methods: {
        handleCreate() {
            // Call refresh owned communities 
            this.$refs.refreshCommunities.fetchOwnedCommunities();
        },
        handleConnection() {
            // Call refresh joined communities 
            this.$refs.refreshCommunities.fetchJoinedCommunities();
        },
        showThanksBanner(kudos) {
			// Show thanks banner
			this.thanksBanner = true;
			this.awardedKudos = kudos;

			// Close banner after 5 seconds
			setTimeout(() => {
				this.closeThanksBanner();
			}, 5000);
		},
		closeThanksBanner() {
			// Close thanks banner
			this.thanksBanner = false;
		}

    }
}
</script>
