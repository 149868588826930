<template>
	<!--Recent Connections Container - no border -->
	<div class="home-container home-container--no-border bottom">
		<!-- Connection grid -->
		<div class="connections-grid">

			<!-- For each connection in recent connections -->
			<div v-for="(connection) in recentConnections.slice(0,10)" :key="connection.connection_date">

				<!-- Dynamically load UserConnection or CommunityConnection -->
				<UserConnection v-if="isUserConnection(connection)" :connection="connection" />
				<CommunityConnection v-else :connection="connection" />

			</div>

			<!-- All Connections Link-->
            <button v-if="!recentConnections.length == 0 && !errorThrown"
                class="connections-load-more secondary-button" @click="allConnectionsNav" role="link" aria-label="View All Connections">
                View All Connections
            </button>

			<!-- If there are no connections to display and there has not been an error -->
			<div v-if="(!recentConnections || recentConnections.length === 0) && !errorThrown" class="no-connections">

				<p><span class="bold-text">You do not have any connections.</span></p>
				<p>Get started by searching for a connection using the search panel.</p>

			</div>

			<!-- If there is an error -->
			<div v-if="errorThrown" class="load-connections-error">

				<p><span class="bold-text">Failed to load recent connections.</span></p>
				<p>Please refresh the page or try again later.</p>

			</div>
		</div>
	</div>
</template>

<script>
import axiosClient from '../../../axiosClient';
import UserConnection from './UserConnection.vue';
import CommunityConnection from './CommunityConnection.vue';
import { EventBus } from '../..//EventBus';

export default {
	name: "RecentConnectionsContainer",
	components: {
		UserConnection,
		CommunityConnection
	},
	data() {
		return {
			recentConnections: [],
			errorThrown: false,
		};
	},
	mounted() {
		this.fetchConnections();

		// Listen for a new connection from quick search bar
		EventBus.on("new-connection", this.fetchConnections)
	},
	unmounted() {
		EventBus.off("new-connection", this.fetchConnections)
	},
	methods: {
		async fetchConnections() {
			try {
				// Fetch recent connections
				const response = await axiosClient.get('/connect/recent_connections');
				this.recentConnections = response.data;

			} catch (error) {

				// Display error if thrown
				this.errorThrown = true
				console.error(error);

			}
		},
		isUserConnection(connection) {

			// Check if the connection is a user or community
			return Object.prototype.hasOwnProperty.call(connection, "email");

		},
		refreshConnections() {
			// Call fetch connection when called following a new connection
			this.fetchConnections();
		},
		allConnectionsNav() {
			this.$router.push('/connections')
		}
	}
};
</script>
